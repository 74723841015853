import { GatsbySeo } from 'gatsby-plugin-next-seo'

import Inverno from '../../components/Campanha/Inverno'

function PageInverno() {
  return (
    <>
      <GatsbySeo title="Inverno 2022 | Decathlon" />
      <Inverno />
    </>
  )
}

export default PageInverno
